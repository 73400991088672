import React from 'react';

import Sidebar from "../components/Sidebar";
import { useNavigate } from 'react-router-dom';
import "../styles/location.css"
import Header from "../components/Header"; // Import CustomNavbar component

const Location: React.FC = () => {
    const navigate = useNavigate();
    const navigateTomuster = () => {
        navigate('/master'); // Change '/home' to the desired path for your home page
    };
    const navigateTcontry = () => {
        navigate('/country'); // Change '/home' to the desired path for your home page
    };
    const navigateToState = () => {
        navigate('/state');
    };
    const navigateToCity = () => {
        navigate('/city');
    };
    const navigateToPinzip = () => {
        navigate('/pinzip');
    };
    return (
        <div className="dashboard">
            <div className="sidebar1">
                <Sidebar />
            </div>
            <div className="main-content">
                <div className='navbar1'>
                    <Header /> {/* Use CustomNavbar component here */}
                </div>
                <div className="content">
                    <div className='subheader'>
                        <ul className='navlink'>
                            <li onClick={navigateTcontry}>Country</li>
                            <li onClick={navigateToState}>State</li>
                            <li onClick={navigateToCity}>City</li>
                            <li onClick={navigateToPinzip}>Pinzip</li>
                            <li></li>
                            <div className='backnavigation' onClick={navigateTomuster}><span>Muster</span> / Location</div>

                        </ul>
                    </div>
                    <div className='musterforms'>
                        <div className='mustercontent'>
                            Location
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Location;

import React, { useState, FormEvent, useEffect } from 'react';
import "../styles/form.css";

interface PinZipType {
    country: string;
    state: string;
    city: string;
    pinzip: string;
    area: string;
}

interface PinZipFormProps {
    addLocation: (location: PinZipType) => void;
    editLocation: (editedLocation: PinZipType) => void;
    locationToEdit?: PinZipType;
    handleClose: () => void;
    countries: string[];
    states: string[];
    cities: string[];
}

const PinzipForm: React.FC<PinZipFormProps> = ({ addLocation, editLocation, locationToEdit, handleClose, countries, states, cities }) => {
    const [formData, setFormData] = useState<PinZipType>({
        country: '',
        state: '',
        city: '',
        pinzip: '',
        area: ''
    });

    const [countrySuggestions, setCountrySuggestions] = useState<string[]>([]);
    const [showCountrySuggestions, setShowCountrySuggestions] = useState<boolean>(false);
    const [stateSuggestions, setStateSuggestions] = useState<string[]>([]);
    const [showStateSuggestions, setShowStateSuggestions] = useState<boolean>(false);
    const [citySuggestions, setCitySuggestions] = useState<string[]>([]);
    const [showCitySuggestions, setShowCitySuggestions] = useState<boolean>(false);
    const [focusedCityIndex, setFocusedCityIndex] = useState<number>(-1);
    const [focusedCountryIndex, setFocusedCountryIndex] = useState<number>(-1);
    const [focusedStateIndex, setFocusedStateIndex] = useState<number>(-1);



    useEffect(() => {
        if (locationToEdit) {
            setFormData(locationToEdit);
        }
    }, [locationToEdit]);

    const handleInputChange = (field: keyof PinZipType, newValue: string) => {
        setFormData(prevState => ({
            ...prevState,
            [field]: newValue
        }));
    };

    const handleCountryInputFocus = () => {
        setCountrySuggestions(countries);
        setShowCountrySuggestions(true);
    };
    const handleCityInputFocus = () => {
        setShowCitySuggestions(true);
        setCitySuggestions(cities);
    };
    const handleStateInputFocus = () => {
        setStateSuggestions(states);
        setShowStateSuggestions(true);
    };
    const handleCountryInputChange = (newValue: string) => {
        handleInputChange('country', newValue);
        if (newValue.trim() !== '') {
            const filteredSuggestions = countries.filter(country =>
                country.toLowerCase().startsWith(newValue.toLowerCase())
            );
            setCountrySuggestions(filteredSuggestions);
            setShowCountrySuggestions(true);
        } else {
            setShowCountrySuggestions(false);
        }
    };

    const handleStateInputChange = (newValue: string) => {
        handleInputChange('state', newValue);
        if (newValue.trim() !== '') {
            const filteredSuggestions = states.filter(state =>
                state.toLowerCase().startsWith(newValue.toLowerCase())
            );
            setStateSuggestions(filteredSuggestions);
            setShowStateSuggestions(true);
        } else {
            setShowStateSuggestions(false);
        }
    };

    const handleCityInputChange = (newValue: string) => {
        handleInputChange('city', newValue);
        if (newValue.trim() !== '') {
            const filteredSuggestions = cities.filter(city =>
                city.toLowerCase().startsWith(newValue.toLowerCase())
            );
            setCitySuggestions(filteredSuggestions);
            setShowCitySuggestions(true);
        } else {
            setShowCitySuggestions(false);
        }
    };

    const handleSuggestionClick = (field: keyof PinZipType, suggestion: string) => {
        handleInputChange(field, suggestion);
        if (field === 'country') setShowCountrySuggestions(false);
        if (field === 'state') setShowStateSuggestions(false);
        if (field === 'city') setShowCitySuggestions(false);
    };

    const handleKeyDown = (e: React.KeyboardEvent, isCountry: boolean) => {
        if (isCountry && showCountrySuggestions) {
            if (e.key === 'ArrowDown') {
                setFocusedCountryIndex((prevIndex) =>
                    prevIndex < countrySuggestions.length - 1 ? prevIndex + 1 : prevIndex
                );
            } else if (e.key === 'ArrowUp') {
                setFocusedCountryIndex((prevIndex) =>
                    prevIndex > 0 ? prevIndex - 1 : prevIndex
                );
            } else if (e.key === 'Enter' && focusedCountryIndex >= 0) {
                handleSuggestionClick('country', countrySuggestions[focusedCountryIndex]);
            }
        } else if (!isCountry && showStateSuggestions) {
            if (e.key === 'ArrowDown') {
                setFocusedStateIndex((prevIndex) =>
                    prevIndex < stateSuggestions.length - 1 ? prevIndex + 1 : prevIndex
                );
            } else if (e.key === 'ArrowUp') {
                setFocusedStateIndex((prevIndex) =>
                    prevIndex > 0 ? prevIndex - 1 : prevIndex
                );
            } else if (e.key === 'Enter' && focusedStateIndex >= 0) {
                handleSuggestionClick('state', stateSuggestions[focusedStateIndex]);
            }
        }
    };
    const handleCityInputKeyDown = (e: React.KeyboardEvent) => {
        console.log('Key pressed in city input:', e.key);
        console.log('Current focused city index:', focusedCityIndex);
        console.log('City suggestions:', citySuggestions);
    
        if (showCitySuggestions) {
            if (e.key === 'ArrowDown') {
                setFocusedCityIndex((prevIndex) =>
                    prevIndex < citySuggestions.length - 1 ? prevIndex + 1 : prevIndex
                );
            } else if (e.key === 'ArrowUp') {
                setFocusedCityIndex((prevIndex) =>
                    prevIndex > 0 ? prevIndex - 1 : prevIndex
                );
            } else if (e.key === 'Enter' && focusedCityIndex >= 0) {
                handleSuggestionClick('city', citySuggestions[focusedCityIndex]);
            }
        }
    };
    
    const handleFormSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (locationToEdit) {
            editLocation(formData);
        } else {
            addLocation(formData);
        }
        setFormData({ country: '', state: '', city: '', pinzip: '', area: '' });
        handleClose();
    };

    return (
        <div className='formdiv'>
            <form onSubmit={handleFormSubmit} style={{ marginBottom: '20px' }}>
                <div className="group">
                    <input
                        type="text"
                        className="input"
                        id="country"
                        name="country"
                        autoComplete="off"
                        onFocus={handleCountryInputFocus}
                        placeholder=" "
                        value={formData.country}
                        onChange={(e) => handleCountryInputChange(e.target.value)}
                        onKeyDown={(e) => handleKeyDown(e, true)}
                        required
                    />
                    <label htmlFor="country">Country</label>
                    <span className="bar"></span>
                    {showCountrySuggestions && (
                        <ul className="suggestions">
                            {countrySuggestions.map((suggestion, index) => (
                                <li
                                    key={index}
                                    className={index === focusedCountryIndex ? 'focused' : ''}
                                    onClick={() => handleSuggestionClick('country', suggestion)}
                                >
                                    {suggestion}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>

                <div className="group">
                    <input
                        type="text"
                        className="input"
                        id="state"
                        name="state"
                        autoComplete="off"
                        placeholder=" "
                        value={formData.state}
                        onFocus={handleStateInputFocus}
                        onChange={(e) => handleStateInputChange(e.target.value)}
                        onKeyDown={(e) => handleKeyDown(e, false)}
                        required
                    />
                    <label htmlFor="state">State</label>
                    <span className="bar"></span>
                    {showStateSuggestions && (
                        <ul className="suggestions">
                            {stateSuggestions.map((suggestion, index) => (
                                <li
                                    key={index}
                                    className={index === focusedStateIndex ? 'focused' : ''}
                                    onClick={() => handleSuggestionClick('state', suggestion)}
                                >
                                    {suggestion}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>

                <div className="group">
                    <input
                        type="text"
                        className="input"
                        id="city"
                        name="city"
                        autoComplete="off"
                        placeholder=" "
                        onFocus={handleCityInputFocus}
                        value={formData.city}
                        onChange={(e) => handleCityInputChange(e.target.value)}
                        onKeyDown={handleCityInputKeyDown} // Add keydown event handler
                        required
                    />
                    <label htmlFor="city">City</label>
                    <span className="bar"></span>
                    {showCitySuggestions && (
                        <ul className="suggestions">
                            {citySuggestions.map((suggestion, index) => (
                                <li
                                    key={index}
                                    className={index === focusedCityIndex ? 'focused' : ''}
                                    onClick={() => handleSuggestionClick('city', suggestion)}
                                >
                                    {suggestion}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>

                <div className="group">
                    <input
                        type="text"
                        className="input"
                        id="pinzip"
                        name="pinzip"
                        placeholder=" "
                        autoComplete="off"
                        value={formData.pinzip}
                        onChange={(e) => handleInputChange('pinzip', e.target.value)}
                        required
                    />
                    <label htmlFor="pinzip">Pinzip</label>
                    <span className="bar"></span>
                </div>

                <div className="group">
                    <input
                        type="text"
                        className="input"
                        id="area"
                        name="area"
                        autoComplete="off"
                        placeholder=" "
                        value={formData.area}
                        onChange={(e) => handleInputChange('area', e.target.value)}
                        required
                    />
                    <label htmlFor="area">Area</label>
                    <span className="bar"></span>
                </div>

                <div className='btns'>
                    <button type="submit" className="btn btn-primary">
                        {locationToEdit ? 'Update Pinzip' : 'Add Pinzip'}
                    </button>
                    <button type="button" className="closeButton" onClick={handleClose}>
                        Close
                    </button>
                </div>
            </form>
        </div>
    );
};

export default PinzipForm;


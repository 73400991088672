import React from 'react';
import Sidebar from "../components/Sidebar";
import { useNavigate } from 'react-router-dom';
import "../styles/master.css";
import Header from "../components/Header"; // Import CustomNavbar component

const Master: React.FC = () => {
  const navigate = useNavigate();
  const navigateToLocation = () => {
    navigate("/location");
  };
  const navigateToPartymaster = () => {
    navigate("/party");
  };
  const navigateToDashboard = () => {
    navigate('/dashboard'); // Change '/home' to the desired path for your home page
  };

  return (
    <div className="dashboard">
      <div className="sidebar1">
        <Sidebar />
      </div>
      <div className="main-content">
        <div className='navbar1'>
          <Header /> {/* Use CustomNavbar component here */}
        </div>
        <div className="content-master">
          <div className='subheader-master'>
            <ul className='navlink-master'>
              <li onClick={navigateToLocation}>Location</li>
              <li onClick={navigateToPartymaster}>Party Master</li>
              <li>Pickup</li>
              <li>Branch</li>
              <li>Shipping</li>
            </ul>
            <div className='backnavigation-master' onClick={navigateToDashboard}>
              <span>Home / Master</span>
            </div> 
          </div>
          <div className='musterforms'>
            <div className='mustercontent'>
              Master
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Master;

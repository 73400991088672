import React, { useState, FormEvent, useEffect } from 'react';
import "../styles/form.css";

interface CityType {
    cityCode: string;
    cityName: string;
    countryName: string;
    stateName: string;
    category: string;
}

interface StateType {
    stateCode: string;
    stateName: string;
    country: string;
    zone: string;
}

interface CityFormProps {
    addLocation: (location: CityType) => void;
    editLocation: (editedLocation: CityType) => void;
    locationToEdit?: CityType;
    handleClose: () => void;
    countries: string[];
    states: StateType[];
}

const CityForm: React.FC<CityFormProps> = ({ addLocation, editLocation, locationToEdit, handleClose, countries, states }) => {
    const [formData, setFormData] = useState<CityType>({
        cityCode: '',
        cityName: '',
        countryName: '',
        stateName: '',
        category: ''
    });

    const [countrySuggestions, setCountrySuggestions] = useState<string[]>([]);
    const [showCountrySuggestions, setShowCountrySuggestions] = useState<boolean>(false);
    const [stateSuggestions, setStateSuggestions] = useState<string[]>([]);
    const [showStateSuggestions, setShowStateSuggestions] = useState<boolean>(false);
    const [focusedCountryIndex, setFocusedCountryIndex] = useState<number>(-1);
    const [focusedStateIndex, setFocusedStateIndex] = useState<number>(-1);

    useEffect(() => {
        if (locationToEdit) {
            setFormData(locationToEdit);
        }
    }, [locationToEdit]);

    const handleCountryInputFocus = () => {
        setCountrySuggestions(countries);
        setShowCountrySuggestions(true);
    };

    const handleStateInputFocus = () => {
        if (formData.countryName) {
            const filteredSuggestions = states
                .filter(state => state.country === formData.countryName)
                .map(state => state.stateName);
            setStateSuggestions(filteredSuggestions);
            setShowStateSuggestions(true);
        } else {
            setStateSuggestions([]);
            setShowStateSuggestions(false);
        }
    };

    const handleCountryInputChange = (newValue: string) => {
        setFormData(prevState => ({
            ...prevState,
            countryName: newValue,
            stateName: '' // Reset state name when country changes
        }));
        if (newValue.trim() !== '') {
            const filteredSuggestions = countries.filter(country =>
                country.toLowerCase().startsWith(newValue.toLowerCase())
            );
            setCountrySuggestions(filteredSuggestions);
            setShowCountrySuggestions(true);
        } else {
            setShowCountrySuggestions(false);
        }
    };

    const handleCountrySuggestionClick = (suggestion: string) => {
        setFormData(prevState => ({
            ...prevState,
            countryName: suggestion,
            stateName: '' // Reset state name when country is selected
        }));
        setShowCountrySuggestions(false);
    };

    const handleStateInputChange = (newValue: string) => {
        setFormData(prevState => ({
            ...prevState,
            stateName: newValue
        }));
        if (newValue.trim() !== '') {
            const filteredSuggestions = states
                .filter(state => state.country === formData.countryName)
                .filter(state => state.stateName.toLowerCase().startsWith(newValue.toLowerCase()))
                .map(state => state.stateName);
            setStateSuggestions(filteredSuggestions);
            setShowStateSuggestions(true);
        } else {
            setShowStateSuggestions(false);
        }
    };

    const handleStateSuggestionClick = (suggestion: string) => {
        setFormData(prevState => ({
            ...prevState,
            stateName: suggestion
        }));
        setShowStateSuggestions(false);
    };

    const handleKeyDown = (e: React.KeyboardEvent, isCountry: boolean) => {
        if (isCountry && showCountrySuggestions) {
            if (e.key === 'ArrowDown') {
                setFocusedCountryIndex((prevIndex) =>
                    prevIndex < countrySuggestions.length - 1 ? prevIndex + 1 : prevIndex
                );
            } else if (e.key === 'ArrowUp') {
                setFocusedCountryIndex((prevIndex) =>
                    prevIndex > 0 ? prevIndex - 1 : prevIndex
                );
            } else if (e.key === 'Enter' && focusedCountryIndex >= 0) {
                handleCountrySuggestionClick(countrySuggestions[focusedCountryIndex]);
            }
        } else if (!isCountry && showStateSuggestions) {
            if (e.key === 'ArrowDown') {
                setFocusedStateIndex((prevIndex) =>
                    prevIndex < stateSuggestions.length - 1 ? prevIndex + 1 : prevIndex
                );
            } else if (e.key === 'ArrowUp') {
                setFocusedStateIndex((prevIndex) =>
                    prevIndex > 0 ? prevIndex - 1 : prevIndex
                );
            } else if (e.key === 'Enter' && focusedStateIndex >= 0) {
                handleStateSuggestionClick(stateSuggestions[focusedStateIndex]);
            }
        }
    };

    const handleFormSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (locationToEdit) {
            editLocation(formData);
        } else {
            addLocation(formData);
        }
        setFormData({ cityCode: '', cityName: '', countryName: '', stateName: '', category: '' });
        handleClose();
    };

    return (
        <div className='formdiv'>
            <form onSubmit={handleFormSubmit} style={{ marginBottom: '20px' }}>
                <div className="group">
                    <input
                        type="text"
                        className="input"
                        id="cityCode"
                        name="cityCode"
                        autoComplete="off"
                        placeholder=" "
                        value={formData.cityCode}
                        onChange={(e) => setFormData(prevState => ({ ...prevState, cityCode: e.target.value }))}
                        required
                    />
                    <label htmlFor="cityCode">City Code</label>
                    <span className="bar"></span>
                </div>

                <div className="group">
                    <input
                        type="text"
                        className="input"
                        id="cityName"
                        name="cityName"
                        autoComplete="off"
                        placeholder=" "
                        value={formData.cityName}
                        onChange={(e) => setFormData(prevState => ({ ...prevState, cityName: e.target.value }))}
                        required
                    />
                    <label htmlFor="cityName">City Name</label>
                    <span className="bar"></span>
                </div>

                <div className="group">
                    <input
                        type="text"
                        className="input"
                        id="country"
                        name="country"
                        autoComplete="off"
                        placeholder=" "
                        value={formData.countryName}
                        onFocus={handleCountryInputFocus}
                        onChange={(e) => handleCountryInputChange(e.target.value)}
                        onKeyDown={(e) => handleKeyDown(e, true)}
                        required
                    />
                    <label htmlFor="country">Country</label>
                    <span className="bar"></span>
                    {showCountrySuggestions && (
                        <ul className="suggestions">
                            {countrySuggestions.map((suggestion, index) => (
                                <li
                                    key={index}
                                    className={index === focusedCountryIndex ? 'focused' : ''}
                                    onClick={() => handleCountrySuggestionClick(suggestion)}
                                >
                                    {suggestion}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>

                <div className="group">
                    <input
                        type="text"
                        className="input"
                        id="state"
                        name="state"
                        autoComplete="off"
                        placeholder=" "
                        value={formData.stateName}
                        onFocus={handleStateInputFocus}
                        onChange={(e) => handleStateInputChange(e.target.value)}
                        onKeyDown={(e) => handleKeyDown(e, false)}
                        required
                    />
                    <label htmlFor="state">State</label>
                    <span className="bar"></span>
                    {showStateSuggestions && (
                        <ul className="suggestions">
                            {stateSuggestions.map((suggestion, index) => (
                                <li
                                    key={index}
                                    className={index === focusedStateIndex ? 'focused' : ''}
                                    onClick={() => handleStateSuggestionClick(suggestion)}
                                >
                                    {suggestion}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>

                <div className='btns'>
                    <button type="submit" className="btn btn-primary">
                        {locationToEdit ? 'Update City' : 'Add City'}
                    </button>
                    <button className="closeButton" onClick={handleClose}>
                        Close
                    </button>
                </div>
            </form>
        </div>
    );
};

export default CityForm;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import StateForm from "./Statedetails";
import "../styles/state.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import {   Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare , faTrash } from '@fortawesome/free-solid-svg-icons';

interface StateType {
    stateCode: string;
    stateName: string;
    country: string;
    zone: string;
}

const State: React.FC = () => {
    const [states, setStates] = useState<StateType[]>(() => {
        const savedStates = localStorage.getItem('states');
        return savedStates ? JSON.parse(savedStates) : [];
    });

    const [countries, setCountries] = useState<string[]>([]);
    const [showModal, setShowModal] = useState(false);
    const [editingIndex, setEditingIndex] = useState<number | null>(null);
    const [searchQuery, setSearchQuery] = useState<string>('');

    const navigate = useNavigate();

    const navigateToLocation = () => navigate('/location');
    const navigateToState = () => navigate('/state');
    const navigateToCity = () => navigate('/city');
    const navigateToCountry = () => navigate('/country');
    const navigateToPinzip = () => navigate('/pinzip');

    const addState = (state: StateType) => {
        const newStates = [...states, state];
        setStates(newStates);
        localStorage.setItem('states', JSON.stringify(newStates));
    };

    const editState = (index: number) => {
        setEditingIndex(index);
        setShowModal(true);
    };

    const handleEdit = (editedState: StateType) => {
        if (editingIndex !== null) {
            const updatedStates = [...states];
            updatedStates[editingIndex] = editedState;
            setStates(updatedStates);
            localStorage.setItem('states', JSON.stringify(updatedStates));
            setEditingIndex(null);
            setShowModal(false);
        }
    };

    const handleDelete = (index: number) => {
        const updatedStates = states.filter((_, i) => i !== index);
        setStates(updatedStates);
        localStorage.setItem('states', JSON.stringify(updatedStates));
    };

    useEffect(() => {
        const savedStates = localStorage.getItem('states');
        if (savedStates) {
            setStates(JSON.parse(savedStates));
        }
    }, []);

    useEffect(() => {
        const savedCountries = localStorage.getItem('countries');
        if (savedCountries) {
            setCountries(JSON.parse(savedCountries));
        }
    }, []);

    const handleCloseModal = () => {
        setShowModal(false);
        setEditingIndex(null);
    };

    const filteredStates = states.filter((state) => 
        state.stateCode.toLowerCase().includes(searchQuery.toLowerCase()) ||
        state.stateName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        state.country.toLowerCase().includes(searchQuery.toLowerCase()) ||
        state.zone.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="dashboard">
            <div className="sidebar1">
                <Sidebar />
            </div>
            <div className="main-content">
                <div className='navbar1'>
                    <Header />
                </div>
                <div className="content">
                    <div className='subheader-state'>
                        <ul className='navlink-state'>
                            <li onClick={navigateToCountry}>Country</li>
                            <li onClick={navigateToState}>State</li>
                            <li onClick={navigateToCity}>City</li>
                            <li onClick={navigateToPinzip}>Pinzip</li>
                            <li></li>
                             
                        </ul>
                        <div className='backnavigation-state' onClick={navigateToLocation}>
                                <span>Location </span> / State
                            </div>
                    </div>
                    <div className='musterforms'>
                        <div className='mustercontent'>
                            <div>State</div>
                            <button className='addbtn' onClick={() => { setEditingIndex(null); setShowModal(true); }}>+ Add</button>
                        </div>
                        <div className="group">
                            <input
                                type="text"
                                className="input search-input"
                                id="isdCode"
                                name="isdCode"
                               
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                required
                            />
                            <label htmlFor="isdCode" className='search-label'>Search</label>
                        </div>
                        <div className='table1'>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th className="table-header">State Code</th>
                                        <th className="table-header">State Name</th>
                                        <th className="table-header">Country</th>
                                        <th className="table-header">Zone</th>
                                        <th className="table-header">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredStates.map((state, index) => (
                                        <tr key={index}>
                                            <td className="table-cell">{state.stateCode}</td>
                                            <td className="table-cell">{state.stateName}</td>
                                            <td className="table-cell">{state.country}</td>
                                            <td className="table-cell">{state.zone}</td>
                                            <td className="table-cell">
                                                <button className='state-btn-edit'  onClick={() => editState(index)}><FontAwesomeIcon icon={faPenToSquare} /></button>
                                                <button  className='state-btn-delete' onClick={() => handleDelete(index)}><FontAwesomeIcon icon={faTrash} /></button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header className='formheader-state'>
                    <Modal.Title>{editingIndex !== null ? 'Edit State' : 'Add State'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <StateForm
                        addLocation={addState}
                        editLocation={handleEdit}
                        locationToEdit={editingIndex !== null ? states[editingIndex] : undefined}
                        handleClose={handleCloseModal}
                        countries={countries}
                    />
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default State;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import PinzipForm from "../pages/pinzipform";
import "../styles/pinzip.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import {  Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons';

interface State {
    stateName: string;
    // Add other properties as needed
}interface city {
    cityName: string;
    // Add other properties as needed
}

interface PinZipType {
    country: string;
    state: string;
    city: string;
    pinzip: string;
    area: string;
}

const Pinzip: React.FC = () => {
    const [pinzips, setPinzips] = useState<PinZipType[]>(() => {
        const savedPinzips = localStorage.getItem('pinzips');
        return savedPinzips ? JSON.parse(savedPinzips) : [];
    });

    const [countries, setCountries] = useState<string[]>([]);
    const [states, setStates] = useState<State[]>([]);
    const [cities, setCities] = useState<city[]>([]);
    const [showModal, setShowModal] = useState(false);
    const [editingIndex, setEditingIndex] = useState<number | null>(null);
    const [searchQuery, setSearchQuery] = useState<string>('');

    const navigate = useNavigate();

    const navigateToLocation = () => navigate('/location');
    const navigateToState = () => navigate('/state');
    const navigateToCity = () => navigate('/city');
    const navigateToCountry = () => navigate('/country');
    const navigateToPinzip = () => navigate('/pinzip');

    const addPinzip = (pinzip: PinZipType) => {
        const newPinzips = [...pinzips, pinzip];
        setPinzips(newPinzips);
        localStorage.setItem('pinzips', JSON.stringify(newPinzips));
    };

    const editPinzip = (index: number) => {
        setEditingIndex(index);
        setShowModal(true);
    };

    const handleEdit = (editedPinzip: PinZipType) => {
        if (editingIndex !== null) {
            const updatedPinzips = [...pinzips];
            updatedPinzips[editingIndex] = editedPinzip;
            setPinzips(updatedPinzips);
            localStorage.setItem('pinzips', JSON.stringify(updatedPinzips));
            setEditingIndex(null);
            setShowModal(false);
        }
    };

    const handleDelete = (index: number) => {
        const updatedPinzips = pinzips.filter((_, i) => i !== index);
        setPinzips(updatedPinzips);
        localStorage.setItem('pinzips', JSON.stringify(updatedPinzips));
    };

    useEffect(() => {
        const savedCities = localStorage.getItem('cities');
        if (savedCities) {
            setCities(JSON.parse(savedCities));
        }
    }, []);

    useEffect(() => {
        const savedCountries = localStorage.getItem('countries');
        if (savedCountries) {
            setCountries(JSON.parse(savedCountries));
        }
    }, []);

    useEffect(() => {
        const savedStates = localStorage.getItem('states');
        if (savedStates) {
            setStates(JSON.parse(savedStates));
        }
    }, []);

    const handleCloseModal = () => {
        setShowModal(false);
        setEditingIndex(null);
    };

    const filteredPinzips = pinzips.filter((pinzip) =>
        pinzip.country.toLowerCase().includes(searchQuery.toLowerCase()) ||
        pinzip.state.toLowerCase().includes(searchQuery.toLowerCase()) ||
        pinzip.city.toLowerCase().includes(searchQuery.toLowerCase()) ||
        pinzip.pinzip.toLowerCase().includes(searchQuery.toLowerCase()) ||
        pinzip.area.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="dashboard">
            <div className="sidebar1">
                <Sidebar />
            </div>
            <div className="main-content">
                <div className='navbar1'>
                    <Header />
                </div>
                <div className="content">
                    <div className='subheader-pinzip'>
                        <ul className='navlink-pinzip'>
                            <li onClick={navigateToCountry}>Country</li>
                            <li onClick={navigateToState}>State</li>
                            <li onClick={navigateToCity}>City</li>
                            <li onClick={navigateToPinzip}>Pinzip</li>
                            
                        </ul>
                        <div className='backnavigation-pinzip' onClick={navigateToLocation}>
                                <span>Location </span> / Pinzip
                            </div> 
                    </div>
                    <div className='musterforms'>
                        <div className='mustercontent'>
                            <div>Pinzip</div>
                            <button className='addbtn' onClick={() => { setEditingIndex(null); setShowModal(true); }}>+ Add</button>
                        </div>
                        <div className="group">
                            <input
                                type="text"
                                className="input search-input"
                                id="searchQuery"
                                name="searchQuery"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                required
                            />
                            <label htmlFor="searchQuery" className='search-label'>Search</label>
                        </div>
                        <div className='table1'>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th className="table-header">Country</th>
                                        <th className="table-header">State</th>
                                        <th className="table-header">City</th>
                                        <th className="table-header">Pinzip</th>
                                        <th className="table-header">Area</th>
                                        <th className="table-header">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredPinzips.map((pinzip, index) => (
                                        <tr key={index}>
                                            <td className="table-cell">{pinzip.country}</td>
                                            <td className="table-cell">{pinzip.state}</td>
                                            <td className="table-cell">{pinzip.city}</td>
                                            <td className="table-cell">{pinzip.pinzip}</td>
                                            <td className="table-cell">{pinzip.area}</td>
                                            <td className="table-cell">
                                                <button className='city-btn-edit' onClick={() => editPinzip(index)}><FontAwesomeIcon icon={faPenToSquare} /></button>
                                                <button className='city-btn-delete' onClick={() => handleDelete(index)}><FontAwesomeIcon icon={faTrash} /></button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header className='formheader-city'>
                    <Modal.Title>{editingIndex !== null ? 'Edit Pinzip' : 'Add Pinzip'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PinzipForm
                        addLocation={addPinzip}
                        editLocation={handleEdit}
                        locationToEdit={editingIndex !== null ? pinzips[editingIndex] : undefined}
                        handleClose={handleCloseModal}
                        countries={countries}
                        states={states.map(state => state.stateName)} // Map state objects to state names
                        cities={cities.map(city => city.cityName)}
                    />
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default Pinzip;

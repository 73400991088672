// src/routes/AppRoutes.tsx

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Master from './pages/Master'; // Import the Home page component
import About from "./pages/About"; // Import the About page component
import Contact from './pages/Contact'; // Import the Contact page component
import Location from "./pages/location"
import Country from "./pages/country"
import ProtectedRoute from './components/ProtectedRoute';
import PublicRoute from './components/PublicRoute';
import State from "./pages/State"
import City from "./pages/City"
import  Pinzip from "./pages/pinzip"
import Party from "./pages/Partymaster"

const AppRoutes: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route path="/master" element={<Master />} /> {/* Route for the Home page */}
        <Route path="/about" element={<About />} /> {/* Route for the About page */}
        <Route path="/contact" element={<Contact />} /> {/* Route for the Contact page */}
        <Route path="/location" element={<Location/>} /> {/* Route for the Contact page */}
        <Route path="/country" element={<Country/>} /> {/* Route for the Contact page */}
        <Route path="/state" element={<State/>} /> {/* Route for the Contact page */}
        <Route path="/city" element={<City/>} /> {/* Route for the Contact page */}
        <Route path="/pinzip" element={<Pinzip/>} />
        <Route path="/party" element={<Party/>} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;

// src/components/PublicRoute.tsx

import React from 'react';
import { Navigate } from 'react-router-dom';

const PublicRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const isAuthenticated = localStorage.getItem('auth') === 'true';

  if (isAuthenticated) {
    return <Navigate to="/dashboard" replace />;
  }

  return children;
};

export default PublicRoute;

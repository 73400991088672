import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import "../styles/form.css";

interface StateType {
    stateCode: string;
    stateName: string;
    country: string;
    zone: string;
}

interface StateFormProps {
    addLocation: (location: StateType) => void;
    editLocation: (editedLocation: StateType) => void;
    locationToEdit?: StateType;
    handleClose: () => void;
    countries: string[];
}

const StateForm: React.FC<StateFormProps> = ({ addLocation, editLocation, locationToEdit, handleClose, countries }) => {
    const [formData, setFormData] = useState<StateType>({
        stateCode: '',
        stateName: '',
        country: '',
        zone: ''
    });

    const [countrySuggestions, setCountrySuggestions] = useState<string[]>([]);
    const [showCountrySuggestions, setShowCountrySuggestions] = useState<boolean>(false);
    const [focusedSuggestionIndex, setFocusedSuggestionIndex] = useState<number>(-1);

    useEffect(() => {
        if (locationToEdit) {
            setFormData(locationToEdit);
        }
    }, [locationToEdit]);

    const handleCountryInputChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { value } = e.target;

        if (value.trim() !== '') {
            const filteredSuggestions = countries.filter(country =>
                country.toLowerCase().startsWith(value.toLowerCase())
            );
            setCountrySuggestions(filteredSuggestions);
        } else {
            setCountrySuggestions(countries);
        }

        setShowCountrySuggestions(true);
        setFormData(prevState => ({
            ...prevState,
            country: value
        }));
        setFocusedSuggestionIndex(-1);
    };

    const handleCountryInputFocus = () => {
        setCountrySuggestions(countries);
        setShowCountrySuggestions(true);
    };

    const handleCountrySuggestionClick = (suggestion: string) => {
        setFormData(prevState => ({
            ...prevState,
            country: suggestion
        }));
        setShowCountrySuggestions(false);
        setFocusedSuggestionIndex(-1);
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (showCountrySuggestions) {
            if (e.key === 'ArrowDown') {
                setFocusedSuggestionIndex((prevIndex) =>
                    prevIndex < countrySuggestions.length - 1 ? prevIndex + 1 : prevIndex
                );
            } else if (e.key === 'ArrowUp') {
                setFocusedSuggestionIndex((prevIndex) =>
                    prevIndex > 0 ? prevIndex - 1 : prevIndex
                );
            } else if (e.key === 'Enter' && focusedSuggestionIndex >= 0) {
                handleCountrySuggestionClick(countrySuggestions[focusedSuggestionIndex]);
            } else if (e.key === 'Escape') {
                setShowCountrySuggestions(false);
            }
        }
    };

    const handleFormSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (locationToEdit) {
            editLocation(formData);
        } else {
            addLocation(formData);
        }
        setFormData({ stateCode: '', stateName: '', country: '', zone: '' });
        handleClose();
    };

    return (
        <div className='formdiv'>
            <form onSubmit={handleFormSubmit} style={{ marginBottom: '20px' }}>
                <div className="group">
                    <input
                        type="text"
                        className="input"
                        id="stateCode"
                        name="stateCode"
                        placeholder=" "
                        value={formData.stateCode}
                        onChange={(e) => setFormData(prevState => ({ ...prevState, stateCode: e.target.value }))}
                        required
                    />
                    <label htmlFor="stateCode">State Code</label>
                    <span className="bar"></span>
                </div>

                <div className="group">
                    <input
                        type="text"
                        className="input"
                        id="stateName"
                        name="stateName"
                        placeholder=" "
                        value={formData.stateName}
                        onChange={(e) => setFormData(prevState => ({ ...prevState, stateName: e.target.value }))}
                        required
                    />
                    <label htmlFor="stateName">State Name</label>
                    <span className="bar"></span>
                </div>

                <div className="group">
                    <input
                        type="text"
                        className="input"
                        id="country"
                        name="country"
                        autoComplete="off"
                        placeholder=" "
                        value={formData.country}
                        onChange={handleCountryInputChange}
                        onFocus={handleCountryInputFocus}
                        onKeyDown={handleKeyDown}
                        required
                    />
                    <label htmlFor="country">Country</label>
                    <span className="bar"></span>
                    {showCountrySuggestions && (
                        <ul className="suggestions">
                            {countrySuggestions.map((suggestion, index) => (
                                <li
                                    key={index}
                                    onClick={() => handleCountrySuggestionClick(suggestion)}
                                    className={index === focusedSuggestionIndex ? 'focused' : ''}
                                >
                                    {suggestion}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>

                <div className="group">
                    <input
                        type="text"
                        className="input"
                        id="zone"
                        name="zone"
                        placeholder=" "
                        value={formData.zone}
                        onChange={(e) => setFormData(prevState => ({ ...prevState, zone: e.target.value }))}
                        required
                    />
                    <label htmlFor="zone">Zone</label>
                    <span className="bar"></span>
                </div>

                <div className='btns'>
                    <button type="submit" className="btn btn-primary">
                        {locationToEdit ? 'Update State' : 'Add State'}
                    </button>
                    <button className="closeButton" onClick={handleClose}>
                        Close
                    </button>
                </div>
            </form>
        </div>
    );
};

export default StateForm;
// main code 
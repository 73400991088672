import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, FloatingLabel } from 'react-bootstrap';
import "../styles/Login.css";
import useAuth from '../hooks/useAuth';

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (email === 'admin@example.com' && password === 'password') {
      login();
      navigate('/dashboard');
    }
  };

  return (
    <div className="widget-container">
      <div className="widget-content">
        <h2 className="widget-title">Sign in to Courier Portal</h2>
        <Form onSubmit={handleLogin}>
          <Form.Group className="form-group">
            <FloatingLabel
              controlId="floatingEmail"
              label="Email address"
              className="mb-3"
            >
              <Form.Control
                type="email"
                name="email"
                placeholder="Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="custom-input"
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group className="form-group">
            <FloatingLabel
              controlId="floatingPassword"
              label="Password"
              className="mb-3 floating-label"
            >
              <Form.Control
                type="password"
                name="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="custom-input"
              />
            </FloatingLabel>
          </Form.Group>
          <Button type="submit" className="sign-in-button">Log in</Button>
          <a href="/" className="forgot-password-link">Forgot password?</a>
        </Form>
      </div>
    </div>
  );
};

export default Login;

import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import "../styles/form.css"

interface CountryType {
    country: string;
    code: string;
    isdCode: string;
    pincode: string;
}

interface CountryFormProps {
    addLocation: (location: CountryType) => void;
    editLocation: (editedLocation: CountryType) => void;
    locationToEdit?: CountryType;
    handleClose: () => void;
}

const CountryForm: React.FC<CountryFormProps> = ({ addLocation, editLocation, locationToEdit, handleClose }) => {
    const [formData, setFormData] = useState<CountryType>({
        country: '',
        code: '',
        isdCode: '',
        pincode: ''
    });

    useEffect(() => {
        if (locationToEdit) {
            setFormData(locationToEdit);
        }
    }, [locationToEdit]);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleFormSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (locationToEdit) {
            editLocation(formData);
        } else {
            addLocation(formData);
        }
        setFormData({ country: '', code: '', isdCode: '', pincode: '' });
        handleClose();
    };

    return (
        <div className='formdiv'>

            <form onSubmit={handleFormSubmit} style={{ marginBottom: '20px' }}>
                <div className="group">
                    <input
                        type="text"
                        className="input"
                        id="country"
                        name="country"
                        autoComplete="off"
                        placeholder=" "
                        value={formData.country}
                        onChange={handleInputChange}
                        required
                    />
                    <label htmlFor="country">Country</label>
                    <span className="bar"></span>
                </div>

                <div className="group">
                    <input
                        type="text"
                        className="input"
                        id="code"
                        name="code"
                        autoComplete="off"
                        placeholder=" "
                        value={formData.code}
                        onChange={handleInputChange}
                        required
                    />
                    <label htmlFor="code">Country Code</label>
                    <span className="bar"></span>
                </div>

                <div className="group">
                    <input
                        type="text"
                        className="input"
                        id="isdCode"
                        name="isdCode"
                        autoComplete="off"
                        placeholder=" "
                        value={formData.isdCode}
                        onChange={handleInputChange}
                        required
                    />
                    <label htmlFor="isdCode">ISD Code</label>
                    <span className="bar"></span>
                </div>
                <div className='btns'>
                <button type="submit" className="btn btn-primary">
                    {locationToEdit ? 'Update Country' : 'Add Country'}
                </button>
                <button className="closeButton" onClick={handleClose}>
                    Close
                </button>
                </div>
            </form>
        </div>
    );
};

export default CountryForm;

import React from 'react';
import Sidebar from "../components/Sidebar";
import "../styles/dashboard.css";
import Header from "../components/Header"; // Import CustomNavbar component
import { useNavigate } from 'react-router-dom';

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const navigateTomuster = () => {
    navigate('/master'); // Change '/home' to the desired path for your home page
  };
  return (
    <div className="dashboard">
      <div className="sidebar1">
        <Sidebar />
      </div>
      <div className="main-content">
          <div className='navbar1'><Header /> {/* Use CustomNavbar component here */}</div>
        <div className="content">
        <div className='subheader'>
            <ul className='navlink'>
              <li onClick={navigateTomuster}>Master</li>
              <li>other</li>
              <li>other</li>
              <li>other</li>
              <li>other</li>
               
            </ul>
          </div>
           
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "../styles/header.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {   faHouse,   } from '@fortawesome/free-solid-svg-icons';
const Header: React.FC = () => {
  const [showSidebar, setShowSidebar] = useState(false);

  const handleToggle = () => {
    setShowSidebar(!showSidebar);
  };

  return (
    <div className="header">
      <Link to="/admin" className='headlogolink'><h4 className='head-logo'>Header</h4></Link>
      <div className='main-container'>
        <button className="toggle-button" onClick={handleToggle}>
          <div className="circle c1">A</div>
        </button>
        <div className={`circles-container ${showSidebar ? 'show' : ''}`}>
          <div className="circle c1"onClick={handleToggle}>A</div>  
        </div>
      </div>
      <div className={`sidebar2 ${showSidebar ? 'show' : ''}`}>
        <ul>
          <li><FontAwesomeIcon icon={faHouse} className='licon'/> Options1</li>
          <li><FontAwesomeIcon icon={faHouse} className='licon'/> Options2</li>
          <li><FontAwesomeIcon icon={faHouse} className='licon'/> Options3</li>
          <li><FontAwesomeIcon icon={faHouse} className='licon'/> Options4</li>
          <li><FontAwesomeIcon icon={faHouse} className='licon'/> Options5</li>
          <li><FontAwesomeIcon icon={faHouse} className='licon'/> Options6</li>
           
        </ul>
      </div>
    </div>
  );
};

export default Header;

import React, { useState, useEffect } from 'react';
import PartyForm from "../pages/partydetails"; // Update this import if needed
import { useNavigate } from 'react-router-dom';
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
 
import "../styles/partymaster.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import {   Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons';

interface PartyType {
    companyName: string;
    vendorCode: string;
    partyCode: string;
    contactPerson: string;
    mobileNo: string;
    address1: string;
    address2: string;
    country: string;
    state: string;
    city: string;
    pinzip: string;
    
    // Add other properties as needed
}
interface State {
    stateName: string;
    // Add other properties as needed
}interface city {
    cityName: string;
    // Add other properties as needed
}
interface PinZip {
     
    pinzip: string;
    
}
const PartyMuster: React.FC = () => {
    const [parties, setParties] = useState<PartyType[]>(() => {
        const savedParties = localStorage.getItem('parties');
        return savedParties ? JSON.parse(savedParties) : [];
    });

    const [showModal, setShowModal] = useState(false);
    const [countries, setCountries] = useState<string[]>([]);
    const [states, setStates] = useState<State[]>([]);
    const [cities, setCities] = useState<city[]>([]);
    const [pinzips, setPinzips] = useState<PinZip[]>([]);
    const [editingIndex, setEditingIndex] = useState<number | null>(null);
    const [searchQuery, setSearchQuery] = useState<string>('');

    const navigate = useNavigate();
    const navigateTomuster = () => {
        navigate('/master'); // Change '/home' to the desired path for your home page
    };


    const addParty = (party: PartyType) => {
        const newParties = [...parties, party];
        setParties(newParties);
        localStorage.setItem('parties', JSON.stringify(newParties));
    };

    const editParty = (index: number) => {
        setEditingIndex(index);
        setShowModal(true);
    };

    const handleEdit = (editedParty: PartyType) => {
        if (editingIndex !== null) {
            const updatedParties = [...parties];
            updatedParties[editingIndex] = editedParty;
            setParties(updatedParties);
            localStorage.setItem('parties', JSON.stringify(updatedParties));
            setEditingIndex(null);
            setShowModal(false);
        }
    };

    const handleDelete = (index: number) => {
        const updatedParties = parties.filter((_, i) => i !== index);
        setParties(updatedParties);
        localStorage.setItem('parties', JSON.stringify(updatedParties));
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setEditingIndex(null);
    };

    const filteredParties = parties.filter((party) =>
        party.companyName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        party.vendorCode.toLowerCase().includes(searchQuery.toLowerCase()) ||
        party.partyCode.toLowerCase().includes(searchQuery.toLowerCase()) ||
        party.contactPerson.toLowerCase().includes(searchQuery.toLowerCase()) ||
        party.mobileNo.toLowerCase().includes(searchQuery.toLowerCase())
    );
    useEffect(() => {
        const savedCountries = localStorage.getItem('countries');
        if (savedCountries) {
            setCountries(JSON.parse(savedCountries));
        }
    }, []);
    useEffect(() => {
        const savedCities = localStorage.getItem('cities');
        if (savedCities) {
            setCities(JSON.parse(savedCities));
        }
    }, []);
    useEffect(() => {
        const savedStates = localStorage.getItem('states');
        if (savedStates) {
            setStates(JSON.parse(savedStates));
        }
    }, []);
    useEffect(() => {
        const savedPinzips = localStorage.getItem('pinzips');
        if (savedPinzips) {
            setPinzips(JSON.parse(savedPinzips));
        }
    }, []);
    
    return (
        <div className="dashboard">
            <div className="sidebar1">
                <Sidebar />
            </div>
            <div className="main-content">
                <div className='navbar1'>
                    <Header />
                </div>
                <div className="content">
                    <div className='subheader-party'>
                        <ul className='navlink-party'>
                            <li >Party Master</li>
                            
                            <div className='backnavigation-party'  onClick={navigateTomuster}>
                                <span>Master </span> / Party Master
                            </div>
                        </ul>
                    </div>
                    <div className='musterforms'>
                        <div className='mustercontent'>
                            <div>Party</div>
                            <button className='addbtn' onClick={() => { setEditingIndex(null); setShowModal(true); }}>+ Add</button>
                        </div>
                        <div className="group">
                            <input
                                type="text"
                                className="input search-input"
                                id="searchQuery"
                                name="searchQuery"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                required
                            />
                            <label htmlFor="searchQuery" className='search-label'>Search</label>
                        </div>
                        <div className='table1'>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th className="table-header">Company Name</th>
                                        <th className="table-header">Vendor Code</th>
                                        <th className="table-header">Party Code</th>
                                        <th className="table-header">Contact Person</th>
                                        <th className="table-header">Mobile No</th>
                                        <th className="table-header">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredParties.map((party, index) => (
                                        <tr key={index}>
                                            <td className="table-cell">{party.companyName}</td>
                                            <td className="table-cell">{party.vendorCode}</td>
                                            <td className="table-cell">{party.partyCode}</td>
                                            <td className="table-cell">{party.contactPerson}</td>
                                            <td className="table-cell">{party.mobileNo}</td>
                                            <td className="table-cell">
                                                <button className='party-btn-edit' onClick={() => editParty(index)}><FontAwesomeIcon icon={faPenToSquare} /></button>
                                                <button className='party-btn-delete' onClick={() => handleDelete(index)}><FontAwesomeIcon icon={faTrash} /></button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header className='formheader-party'>
                    <Modal.Title>{editingIndex !== null ? 'Edit Party' : 'Add Party'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PartyForm
                        addParty={addParty}
                        editParty={handleEdit}
                        partyToEdit={editingIndex !== null ? parties[editingIndex] : undefined}
                        handleClose={handleCloseModal}
                        countries={countries}
                        states={states.map(state => state.stateName)} // Map state objects to state names
                        cities={cities.map(city => city.cityName)}
                        pinzips={pinzips.map(party => party.pinzip)}
                    />
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default PartyMuster;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import CountryForm from "./Countrydetails"; // Adjust the import path as needed
import "../styles/country.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import {  Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare , faTrash } from '@fortawesome/free-solid-svg-icons';

interface LocationType {
    country: string;
    code: string;
    isdCode: string;
    pincode: string;
}

const Country: React.FC = () => {
    const [locations, setLocations] = useState<LocationType[]>(() => {
        const savedLocations = localStorage.getItem('locations');
        return savedLocations ? JSON.parse(savedLocations) : [];
    });

    const [searchQuery, setSearchQuery] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [editingIndex, setEditingIndex] = useState<number | null>(null);

    const navigate = useNavigate();

    const navigateToLocation = () => {
        navigate('/location');
    };
    const navigateToState = () => {
        navigate('/state');
    };
    const navigateToCity = () => {
        navigate('/city');
    };
    const navigateToPinzip = () => {
        navigate('/pinzip');
    };
    const addLocation = (location: LocationType) => {
        const newLocations = [...locations, location];
        setLocations(newLocations);
        localStorage.setItem('locations', JSON.stringify(newLocations));

        // Update countries in localStorage
        const countries = newLocations.map(location => location.country);
        localStorage.setItem('countries', JSON.stringify(countries));
    };

    const editLocation = (index: number) => {
        setEditingIndex(index);
        setShowModal(true);
    };

    const handleEdit = (editedLocation: LocationType) => {
        if (editingIndex !== null) {
            const updatedLocations = [...locations];
            updatedLocations[editingIndex] = editedLocation;
            setLocations(updatedLocations);
            localStorage.setItem('locations', JSON.stringify(updatedLocations));
            setEditingIndex(null);
            setShowModal(false);
        }
    };

    const handleDelete = (index: number) => {
        const updatedLocations = locations.filter((_, i) => i !== index);
        setLocations(updatedLocations);
        localStorage.setItem('locations', JSON.stringify(updatedLocations));

        // Update countries in localStorage
        const countries = updatedLocations.map(location => location.country);
        localStorage.setItem('countries', JSON.stringify(countries));
    };

    useEffect(() => {
        const savedLocations = localStorage.getItem('locations');
        if (savedLocations) {
            setLocations(JSON.parse(savedLocations));
        }
    }, []);

    const handleCloseModal = () => {
        setShowModal(false);
        setEditingIndex(null);
    };

    const filteredLocations = locations.filter(location =>
        location.country.toLowerCase().includes(searchQuery.toLowerCase()) ||
        location.code.toLowerCase().includes(searchQuery.toLowerCase()) ||
        location.isdCode.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="dashboard">
            <div className="sidebar1">
                <Sidebar />
            </div>
            <div className="main-content">
                <div className='navbar1'>
                    <Header />
                </div>
                <div className="content">
                    <div className='subheader-country'>
                        <ul className='navlink-country'>
                            <li>Country</li>
                            <li onClick={navigateToState}>State</li>
                            <li onClick={navigateToCity}>City</li>
                            <li onClick={navigateToPinzip}>Pinzip</li>
                             
                            
                        </ul>
                        <div className='backnavigation-country' onClick={navigateToLocation}>
                                <span>Location </span> / Country
                            </div>
                    </div>
                    <div className='musterforms'>
                        <div className='mustercontent'>
                            <div>Country</div>
                            <button className='addbtn' onClick={() => { setEditingIndex(null); setShowModal(true); }}>+ Add</button>
                        </div>
                        <div className='table1'>
                            <div className="group-1 search-input">
                                <input
                                    type="text"
                                    className="input-1 search-input"
                                    id="isdCode"
                                    name="isdCode"
                                    placeholder=" "
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    required
                                />
                                <label htmlFor="isdCode" className='search-label'>Search</label>

                            </div>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th className="table-header-country">Country</th>
                                        <th className="table-header-country">Code</th>
                                        <th className="table-header-country">ISD Code</th>
                                        <th className="table-header-country">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredLocations.map((location, index) => (
                                        <tr key={index}>
                                            <td className="table-cell-country">{location.country}</td>
                                            <td className="table-cell-country">{location.code}</td>
                                            <td className="table-cell-country">{location.isdCode}</td>
                                            <td className="table-cell-country">
                                                <button  className='country-btn-edit' onClick={() => editLocation(index)}><FontAwesomeIcon icon={faPenToSquare} /></button>
                                                <button  className='country-btn-delete' onClick={() => handleDelete(index)}><FontAwesomeIcon icon={faTrash} /></button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header   className='formheader-country'>
                    <Modal.Title>{editingIndex !== null ? 'Edit Country' : 'Add Country'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CountryForm 
                        addLocation={addLocation}
                        editLocation={handleEdit}
                        locationToEdit={editingIndex !== null ? locations[editingIndex] : undefined}
                        handleClose={handleCloseModal}
                    />
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default Country;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/sidebar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight, faHouse, faInfo, faPhone, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import useAuth from "../hooks/useAuth";

const Sidebar: React.FC = () => {
  const [expanded, setExpanded] = useState(false);
  const [expandedItem, setExpandedItem] = useState<string | null>(null);
  const navigate = useNavigate();
  const { logout } = useAuth();

  const toggleSidebar = () => {
    setExpanded(!expanded);
    if (!expanded) {
      setExpandedItem(null);
    }
  };

  const handleLogout = () => {
    logout();
    navigate('/');
  };
  const navigateToLocation = () =>{
    navigate("/location")
  }

  const toggleItem = (label: string) => {
    if (expanded) {
      setExpandedItem(expandedItem === label ? null : label);
    }
  };
  const navigateTodashboard = () => {
    toggleItem('dashboard');
    navigate('/dashboard'); // Change '/home' to the desired path for your home page
  };
  const navigateTomaster = () => {
    toggleItem('muster');
    navigate('/master'); // Change '/home' to the desired path for your home page
  };

  const navigateToAbout = () => {
    toggleItem('About');
    navigate('/about'); // Change '/about' to the desired path for your about page
  };

  const navigateToContact = () => {
    toggleItem('Contact');
    navigate('/contact'); // Change '/contact' to the desired path for your contact page
  };

  return (
    <div className={`app ${expanded ? 'expanded' : ''}`}>
      <div className="sidebar">
        <div className="sidebar-logo">
          <img src="https://picsum.photos/200/300" alt="Logo" />
        </div>
        <button className="expand-button" onClick={toggleSidebar}>
          {expanded ? <FontAwesomeIcon icon={faAngleDoubleLeft} /> : <FontAwesomeIcon icon={faAngleDoubleRight} />}
        </button>
        <div className="sidebar-items">
          <div>
          <div
              className={`sidebar-item ${expanded && expandedItem === 'dashboard' ? 'expanded-item' : ''}`}
              onClick={navigateTodashboard}
            >
              <div className="iconsdiv">
                <div className="sidebar-icon"><FontAwesomeIcon icon={faHouse} /></div>
              </div>
              {expanded && (
                <div className="sidebar-details">
                  <div className="description">dashboard</div>
                </div>
              )}
            </div>
            <div
              className={`sidebar-item ${expanded && expandedItem === 'muster' ? 'expanded-item' : ''}`}
              onClick={navigateTomaster}
            >
              <div className="iconsdiv">
                <div className="sidebar-icon"><FontAwesomeIcon icon={faHouse} /></div>
              </div>
              {expanded && (
                <div className="sidebar-details">
                  <div className="description" onClick={navigateTomaster}>Master</div>
                </div>
              )}
            </div>
            {expanded && expandedItem === 'muster' && (
              <div className="sidebar-sub-options">
                <div className="sidebar-sub-option"  onClick={navigateToLocation}><span className="sub-sidebar-icon"><FontAwesomeIcon icon={faHouse} /></span> Location</div>
                <div className="sidebar-sub-option"><span className="sub-sidebar-icon"><FontAwesomeIcon icon={faHouse} /></span> User</div>
                <div className="sidebar-sub-option"><span className="sub-sidebar-icon"><FontAwesomeIcon icon={faHouse} /></span> Pickup</div>
                <div className="sidebar-sub-option"><span className="sub-sidebar-icon"><FontAwesomeIcon icon={faHouse} /></span> Branch</div>
                <div className="sidebar-sub-option"><span className="sub-sidebar-icon"><FontAwesomeIcon icon={faHouse} /></span> Shipping</div>
              </div>
            )}
          </div>

          <div>
            <div
              className={`sidebar-item ${expanded && expandedItem === 'About' ? 'expanded-item' : ''}`}
              onClick={navigateToAbout}
            >
              <div className="iconsdiv">
                <div className="sidebar-icon"><FontAwesomeIcon icon={faInfo} /></div>
              </div>
              {expanded && (
                <div className="sidebar-details">
                  <div className="description">About</div>
                </div>
              )}
            </div>
            {expanded && expandedItem === 'About' && (
              <div className="sidebar-sub-options">
                <div className="sidebar-sub-option">Company</div>
                <div className="sidebar-sub-option">Team</div>
                <div className="sidebar-sub-option">Careers</div>
              </div>
            )}
          </div>

          <div>
            <div
              className={`sidebar-item ${expanded && expandedItem === 'Contact' ? 'expanded-item' : ''}`}
              onClick={navigateToContact}
            >
              <div className="iconsdiv">
                <div className="sidebar-icon"><FontAwesomeIcon icon={faPhone} /></div>
              </div>
              {expanded && (
                <div className="sidebar-details">
                  <div className="description">Contact</div>
                </div>
              )}
            </div>
            {expanded && expandedItem === 'Contact' && (
              <div className="sidebar-sub-options">
                <div className="sidebar-sub-option">Email</div>
                <div className="sidebar-sub-option">Phone</div>
                <div className="sidebar-sub-option">Location</div>
              </div>
            )}
          </div>
          
        </div>
        {expanded && (
          <div className="sidebar-footer">
            <div className="user-details">
              <img className="user-img" src="https://picsum.photos/200/300" alt="User" />
              <div className="user-info">
                <div className="user-name">John Doe</div>
                <div className="logout-button">
                  <button onClick={handleLogout}><FontAwesomeIcon icon={faRightFromBracket} /> Logout</button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Sidebar;

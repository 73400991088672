import React, { useState, useEffect, FormEvent } from 'react';
import "../styles/form.css";

interface PartyType {
    companyName: string;
    vendorCode: string;
    partyCode: string;
    contactPerson: string;
    mobileNo: string;
    address1: string;
    address2: string;
    country: string;
    state: string;
    city: string;
    pinzip: string;
    
}

interface PartyFormProps {
    addParty: (party: PartyType) => void;
    editParty: (editedParty: PartyType) => void;
    partyToEdit?: PartyType;
    handleClose: () => void;
    countries: string[];
    states: string[];
    cities: string[];
    pinzips: string[];

}

const PartyForm: React.FC<PartyFormProps> = ({ addParty, editParty, partyToEdit, handleClose, countries, states, cities, pinzips }) => {
    const [formData, setFormData] = useState<PartyType>({
        companyName: '',
        vendorCode: '',
        partyCode: '',
        contactPerson: '',
        mobileNo: '',
        address1: '',
        address2: '',
        country: '',
        state: '',
        city: '',
        pinzip:''

    });

    const [countrySuggestions, setCountrySuggestions] = useState<string[]>([]);
    const [showCountrySuggestions, setShowCountrySuggestions] = useState<boolean>(false);
    const [stateSuggestions, setStateSuggestions] = useState<string[]>([]);
    const [showStateSuggestions, setShowStateSuggestions] = useState<boolean>(false);
    const [citySuggestions, setCitySuggestions] = useState<string[]>([]);
    const [showCitySuggestions, setShowCitySuggestions] = useState<boolean>(false);
    const [partySuggestions, setPartySuggestions] = useState<string[]>([]);
    const [showPartySuggestions, setShowPartySuggestions] = useState<boolean>(false);

    useEffect(() => {
        if (partyToEdit) {
            setFormData(partyToEdit);
        }
    }, [partyToEdit]);

    const handleInputChange = (field: keyof PartyType, newValue: string) => {
        setFormData(prevState => ({
            ...prevState,
            [field]: newValue
        }));
    };

    const handleCountryInputChange = (newValue: string) => {
        handleInputChange('country', newValue);
        if (newValue.trim() !== '') {
            const filteredSuggestions = countries.filter(country =>
                country.toLowerCase().startsWith(newValue.toLowerCase())
            );
            setCountrySuggestions(filteredSuggestions);
            setShowCountrySuggestions(true);
        } else {
            setShowCountrySuggestions(false);
        }
    };
    const handleStateInputChange = (newValue: string) => {
        handleInputChange('state', newValue);
        if (newValue.trim() !== '') {
            const filteredSuggestions = states.filter(state =>
                state.toLowerCase().startsWith(newValue.toLowerCase())
            );
            setStateSuggestions(filteredSuggestions);
            setShowStateSuggestions(true);
        } else {
            setShowStateSuggestions(false);
        }
    };
    const handleCityInputChange = (newValue: string) => {
        handleInputChange('city', newValue);
        if (newValue.trim() !== '') {
            const filteredSuggestions = cities.filter(city =>
                city.toLowerCase().startsWith(newValue.toLowerCase())
            );
            setCitySuggestions(filteredSuggestions);
            setShowCitySuggestions(true);
        } else {
            setShowCitySuggestions(false);
        }
    };
    const handlePartyInputChange = (newValue: string) => {
        handleInputChange('pinzip', newValue);
        if (newValue.trim() !== '') {
            const filteredSuggestions = pinzips.filter(party =>
                party.toLowerCase().startsWith(newValue.toLowerCase())
            );
            setPartySuggestions(filteredSuggestions);
            setShowPartySuggestions(true);
        } else {
            setShowPartySuggestions(false);
        }
    };


    const handleSuggestionClick = (field: keyof PartyType, suggestion: string) => {
        handleInputChange(field, suggestion);
        if (field === 'country') setShowCountrySuggestions(false);
        if (field === 'state') setShowStateSuggestions(false);
        if (field === 'city') setShowCitySuggestions(false);
        if (field === 'pinzip') setShowPartySuggestions(false);
         
    };

    const handleFormSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (partyToEdit) {
            editParty(formData);
        } else {
            addParty(formData);
        }
        setFormData({
            companyName: '',
            vendorCode: '',
            partyCode: '',
            contactPerson: '',
            mobileNo: '',
            address1: '',
            address2: '',
            country: '',
            state:'',
            city:'',
            pinzip:''
        }); // Reset form fields
        handleClose();
    };

    return (
        <div className='formdiv'>
            <form onSubmit={handleFormSubmit} style={{ marginBottom: '20px' }}>
                {/* Company Name */}
                <div className="group">
                    <input
                        type="text"
                        className="input"
                        id="companyName"
                        name="companyName"
                        autoComplete="off"
                        placeholder=" "
                        value={formData.companyName}
                        onChange={(e) => handleInputChange('companyName', e.target.value)}
                        required
                    />
                    <label htmlFor="companyName">Company Name</label>
                    <span className="bar"></span>
                </div>

                {/* Vendor Code */}
                <div className="group">
                    <input
                        type="text"
                        className="input"
                        id="vendorCode"
                        name="vendorCode"
                        autoComplete="off"
                        placeholder=" "
                        value={formData.vendorCode}
                        onChange={(e) => handleInputChange('vendorCode', e.target.value)}
                        required
                    />
                    <label htmlFor="vendorCode">Vendor Code</label>
                    <span className="bar"></span>
                </div>

                {/* Party Code */}
                <div className="group">
                    <input
                        type="text"
                        className="input"
                        id="partyCode"
                        name="partyCode"
                        autoComplete="off"
                        placeholder=" "
                        value={formData.partyCode}
                        onChange={(e) => handleInputChange('partyCode', e.target.value)}
                        required
                    />
                    <label htmlFor="partyCode">Party Code</label>
                    <span className="bar"></span>
                </div>

                {/* Contact Person */}
                <div className="group">
                    <input
                        type="text"
                        className="input"
                        id="contactPerson"
                        name="contactPerson"
                        autoComplete="off"
                        placeholder=" "
                        value={formData.contactPerson}
                        onChange={(e) => handleInputChange('contactPerson', e.target.value)}
                        required
                    />
                    <label htmlFor="contactPerson">Contact Person</label>
                    <span className="bar"></span>
                </div>

                {/* Mobile No */}
                <div className="group">
                    <input
                        type="text"
                        className="input"
                        id="mobileNo"
                        name="mobileNo"
                        autoComplete="off"
                        placeholder=" "
                        value={formData.mobileNo}
                        onChange={(e) => handleInputChange('mobileNo', e.target.value)}
                        required
                    />
                    <label htmlFor="mobileNo">Mobile No</label>
                    <span className="bar"></span>
                </div>

                {/* Address 1 */}
                <div className="group">
                    <input
                        type="text"
                        className="input"
                        id="address1"
                        name="address1"
                        autoComplete="off"
                        placeholder=" "
                        value={formData.address1}
                        onChange={(e) => handleInputChange('address1', e.target.value)}
                        required
                    />
                    <label htmlFor="address1">Address 1</label>
                    <span className="bar"></span>
                </div>

                {/* Address 2 */}
                <div className="group">
                    <input
                        type="text"
                        className="input"
                        id="address2"
                        name="address2"
                        autoComplete="off"
                        placeholder=" "
                        value={formData.address2}
                        onChange={(e) => handleInputChange('address2', e.target.value)}
                        required
                    />
                    <label htmlFor="address2">Address 2</label>
                    <span className="bar"></span>
                </div>

                {/* Country */}
                <div className="group">
                    <input
                        type="text"
                        className="input"
                        id="country"
                        name="country"
                        autoComplete="off"
                        placeholder=" "
                        value={formData.country}
                        onChange={(e) => handleCountryInputChange(e.target.value)}
                        required
                    />
                    <label htmlFor="country">Country</label>
                    <span className="bar"></span>
                    {/* Suggestions */}
                    {showCountrySuggestions && (
                        <ul className="suggestions">
                            {countrySuggestions.map((suggestion, index) => (
                                <li key={index} onClick={() => handleSuggestionClick('country', suggestion)}>{suggestion}</li>
                            ))}
                        </ul>
                    )}
                </div>
                <div className="group">
                    <input
                        type="text"
                        className="input"
                        id="state"
                        name="state"
                        autoComplete="off"
                        placeholder=" "
                        value={formData.state}
                        onChange={(e) => handleStateInputChange(e.target.value)}
                        required
                    />
                    <label htmlFor="state">State</label>
                    <span className="bar"></span>
                    {showStateSuggestions && (
                        <ul className="suggestions">
                            {stateSuggestions.map((suggestion, index) => (
                                <li key={index} onClick={() => handleSuggestionClick('state', suggestion)}>{suggestion}</li>
                            ))}
                        </ul>
                    )}
                </div>
                <div className="group">
                    <input
                        type="text"
                        className="input"
                        id="city"
                        name="city"
                        autoComplete="off"
                        placeholder=" "
                        value={formData.city}
                        onChange={(e) => handleCityInputChange(e.target.value)}
                        required
                    />
                    <label htmlFor="city">City</label>
                    <span className="bar"></span>
                    {showCitySuggestions && (
                        <ul className="suggestions">
                            {citySuggestions.map((suggestion, index) => (
                                <li key={index} onClick={() => handleSuggestionClick('city', suggestion)}>{suggestion}</li>
                            ))}
                        </ul>
                    )}
                </div>
                <div className="group">
                    <input
                        type="text"
                        className="input"
                        id="party"
                        name="party"
                        autoComplete="off"
                        placeholder=" "
                        value={formData.pinzip}
                        onChange={(e) => handlePartyInputChange(e.target.value)}
                        required
                    />
                    <label htmlFor="party">Pinzip</label>
                    <span className="bar"></span>
                    {showPartySuggestions && (
                        <ul className="suggestions">
                            {partySuggestions.map((suggestion, index) => (
                                <li key={index} onClick={() => handleSuggestionClick('pinzip', suggestion)}>{suggestion}</li>
                            ))}
                        </ul>
                    )}
                </div>

                <button className=" btn btn-primary" type="submit" >
                    {partyToEdit ? 'Update' : 'Submit'}
                </button>
                <button type="button" className="closeButton" onClick={handleClose}>
                    Close
                </button>
            </form>
        </div>
    );
};

export default PartyForm;


import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import CityForm from "../pages/citydetails"; // Update the import path
import "../styles/city.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons';

interface CityType {
    cityCode: string;
    cityName: string;
    countryName: string;
    stateName: string;
    category: string;
}

interface StateType {
    stateCode: string;
    stateName: string;
    country: string;
    zone: string;
}

const City: React.FC = () => {
    const [cities, setCities] = useState<CityType[]>([]);
    const [countries, setCountries] = useState<string[]>([]);
    const [states, setStates] = useState<StateType[]>([]);
    const [showModal, setShowModal] = useState(false);
    const [editingIndex, setEditingIndex] = useState<number | null>(null);
    const [searchQuery, setSearchQuery] = useState<string>('');

    const navigate = useNavigate();

    const navigateToLocation = () => navigate('/location');
    const navigateToState = () => navigate('/state');
    const navigateToCity = () => navigate('/city');
    const navigateToCountry = () => navigate('/country');
    const navigateToPinzip = () => navigate('/pinzip');

    const addCity = (city: CityType) => {
        const newCities = [...cities, city];
        setCities(newCities);
        localStorage.setItem('cities', JSON.stringify(newCities));
    };

    const editCity = (index: number) => {
        setEditingIndex(index);
        setShowModal(true);
    };

    const handleEdit = (editedCity: CityType) => {
        if (editingIndex !== null) {
            const updatedCities = [...cities];
            updatedCities[editingIndex] = editedCity;
            setCities(updatedCities);
            localStorage.setItem('cities', JSON.stringify(updatedCities));
            setEditingIndex(null);
            setShowModal(false);
        }
    };

    const handleDelete = (index: number) => {
        const updatedCities = cities.filter((_, i) => i !== index);
        setCities(updatedCities);
        localStorage.setItem('cities', JSON.stringify(updatedCities));
    };

    useEffect(() => {
        const savedCities = localStorage.getItem('cities');
        if (savedCities) {
            setCities(JSON.parse(savedCities));
        }
    }, []);

    useEffect(() => {
        const savedCountries = localStorage.getItem('countries');
        if (savedCountries) {
            setCountries(JSON.parse(savedCountries));
        }
    }, []);

    useEffect(() => {
        const savedStates = localStorage.getItem('states');
        if (savedStates) {
            setStates(JSON.parse(savedStates));
        }
    }, []);

    const handleCloseModal = () => {
        setShowModal(false);
        setEditingIndex(null);
    };

    const filteredCities = cities.filter((city) =>
        city.cityCode.toLowerCase().includes(searchQuery.toLowerCase()) ||
        city.cityName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        city.countryName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        city.stateName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        city.category.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="dashboard">
            <div className="sidebar1">
                <Sidebar />
            </div>
            <div className="main-content">
                <div className='navbar1'>
                    <Header />
                </div>
                <div className="content">
                    <div className='subheader-city'>
                        <ul className='navlink-city'>
                            <li onClick={navigateToCountry}>Country</li>
                            <li onClick={navigateToState}>State</li>
                            <li onClick={navigateToCity}>City</li>
                            <li onClick={navigateToPinzip}>Pincode</li>
                        </ul>
                        <div className='backnavigation-city' onClick={navigateToLocation}>
                            <span>Location </span> / City
                        </div>
                    </div>
                    <div className='musterforms'>
                        <div className='mustercontent'>
                            <div>City</div>
                            <button className='addbtn' onClick={() => { setEditingIndex(null); setShowModal(true); }}>+ Add</button>
                        </div>
                        <div className="group">
                            <input
                                type="text"
                                className="input search-input"
                                id="isdCode"
                                name="isdCode"
                                placeholder=" "
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                required
                            />
                            <label htmlFor="isdCode" className='search-label'>Search</label>
                        </div>
                        <div className='table1'>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th className="table-header">City Code</th>
                                        <th className="table-header">City Name</th>
                                        <th className="table-header">Country</th>
                                        <th className="table-header">State</th>
                                        <th className="table-header">Category</th>
                                        <th className="table-header">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredCities.map((city, index) => (
                                        <tr key={index}>
                                            <td className="table-cell">{city.cityCode}</td>
                                            <td className="table-cell">{city.cityName}</td>
                                            <td className="table-cell">{city.countryName}</td>
                                            <td className="table-cell">{city.stateName}</td>
                                            <td className="table-cell">{city.category}</td>
                                            <td className="table-cell">
                                                <button className='city-btn-edit' onClick={() => editCity(index)}><FontAwesomeIcon icon={faPenToSquare} /></button>
                                                <button className='city-btn-delete' onClick={() => handleDelete(index)}><FontAwesomeIcon icon={faTrash} /></button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header className='formheader-city'>
                    <Modal.Title>{editingIndex !== null ? 'Edit City' : 'Add City'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CityForm
                        addLocation={addCity}
                        editLocation={handleEdit}
                        locationToEdit={editingIndex !== null ? cities[editingIndex] : undefined}
                        handleClose={handleCloseModal}
                        countries={countries}
                        states={states}
                    />
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default City;

import React from 'react';
import Sidebar from "../components/Sidebar";
import "../styles/dashboard.css";
import Header from "../components/Header"; // Import CustomNavbar component

const Dashboard: React.FC = () => {
  return (
    <div className="dashboard">
      <div className="sidebar1">
        <Sidebar />
      </div>
      <div className="main-content">
          <div className='navbar1'><Header /> {/* Use CustomNavbar component here */}</div>
        <div className="content">about</div>
      </div>
    </div>
  );
};

export default Dashboard;
